<template>
  <b-container class="pt-3">
    <b-form @submit.prevent="uploadFile">
      <b-row>
        <b-col>
          <h2>Upload Media</h2>
        </b-col>
        <b-col cols="*" class="mr-3">
          <b-button
            variant="primary"
            type="submit"
            :disabled="!Boolean(file) || uploading"
            >Upload <b-spinner small v-if="uploading"
          /></b-button>
        </b-col>
      </b-row>
      <hr class="mt-1 mb-5" />

      <b-row>
        <b-col>
          <b-form-input
            placeholder="Enter name"
            v-model="name"
            autocomplete="off"
            class="mb-3"
            required
          ></b-form-input>
        </b-col>

        <b-col>
          <b-form-select
            v-model="media_type"
            :options="options"
            class="mb-3"
            required
          ></b-form-select>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-textarea v-model="description" class="mb-3">
          </b-form-textarea>
        </b-col>
      </b-row>
    </b-form>

    <b-row>
      <b-col>
        <b-form-file
          v-model="file"
          :state="Boolean(file)"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          class="mb-3"
        ></b-form-file>
        <b-row>
          <b-col>
            <b-button @click="file = null" :disabled="uploading" block
              >Clear</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      uploading: false,
      url: "",
      name: "",
      description: "",
      media_type: null,
      options: [
        { value: null, text: "Please select a media type" },
        { value: "image", text: "Image" },
        { value: "video", text: "Video" },
      ],
    };
  },
  methods: {
    async uploadFile() {
      this.uploading = true;
      try {
        const re = /(?:\.([^.]+))?$/;

        const ext = re.exec(this.file.name)[1];
        let response = await this.$http.post(
          this.$store.state.apiURL + "/media",
          {
            name: this.name,
            description: this.description,
            media_type: this.media_type,
            file_extension: ext,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        var options = {
          headers: {
            "Content-Type": this.file.type,
            "x-amz-acl": "public-read",
          },
        };

        await this.$http.put(response.data.presigned_url, this.file, options);

        let confirmResponse = await this.$http.post(
          this.$store.state.apiURL + "/media/" + response.data.id,
          {
            confirm_action: "object",
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.url = confirmResponse.data.url;
        this.file = null;

        this.$router.push({
          name: "editMedia",
          params: { id: confirmResponse.data.id },
        });
      } catch (error) {
        this.handleError(error);
      }
      this.uploading = false;
    },
  },
};
</script>

<style>
</style>